export function format_number( _number ){

    
    // _number = _number.toLocaleString('ru-RU', { maximumFractionDigits: 2 })
    // _number = _number.replace(',', '.')
    // return _number

    // const formatter = new Intl.NumberFormat('ru-RU', {
    //     style: 'currency',
    //     currency: 'RUB',
    //     minimumFractionDigits: 2
    // })
    // console.log('formatter', formatter);
    // return formatter.format( _number )

    // _number = parseFloat( _number )   
    // _number = _number.toLocaleString('ru-RU', { maximumFractionDigits: 2 })
    // // _number = _number.replace(',', '.')
    // // _number = _number.replace(/\s/g, ',')
    // return parseFloat( _number )

    const formatter = new Intl.NumberFormat('ru-RU', {
        // style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0
    })
    var _number = formatter.format( _number )
        _number = _number.replace(',', '.')
        _number = _number.replace(/\s/g, ',')

    _number += '  ₽'

    return _number

}

export function capitalize(string) {
    return ( string !== undefined && string.length > 0 ) ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export function godgodalet(year, forms) {
    let lastDigit = year % 10;
    let lastTwoDigits = year % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return forms[2]; // лет
    } else {
      switch (lastDigit) {
        case 1:
          return forms[0]; // год
        case 2:
        case 3:
        case 4:
          return forms[1]; // года
        default:
          return forms[2]; // лет
      }
    }
}

export function format_date( _date ){
    let date = new Date( _date * 1000 )
    let _k = _v => {
        if( parseInt( _v ) < 10 ) return '0'+((_v+1).toString())
        if( parseInt( _v ) >= 10 ) return (_v+1).toString()
    }
    return `${_k(date.getDay())}.${_k(date.getMonth())}.${date.getFullYear()}`
}   

// export function getMonthName (monthNumber, _t = false){
//     const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
//       "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
//     const monthNames2 = ["Янв.", "Фев.", "Мар.", "Апр.", "Май", "Июн.",
//       "Июл.", "Авг.", "Сен.", "Окт.", "Ноя.", "Дек."];
//     return (_t ) ? monthNames[monthNumber - 1] : monthNames2[monthNumber - 1];
// }
// // '2024-02-13T22:22:03.875Z' -> "14.02.2024, 01:22:03"
// export function getDateFormat_1( date ){
//     return new Date( date ).toLocaleString()
// }

// // in -> Object { month: 10, year: 2023 } == out - 2023-11
// export function format_report_period_date_1( _date ){
//     let _k = _v => {
//         if( parseInt( _v ) < 10 ) return '0'+((_v+1).toString())
//         if( parseInt( _v ) >= 10 ) return (_v+1).toString()
//     }
//     return `${_date.year}-${_k(_date.month)}`
// }   

// // in -> Object { month: 10, year: 2023 } == out - 11.2023
// export function format_report_period_date_2( _date ){
//     let _k = _v => {
//         if( parseInt( _v ) < 10 ) return '0'+((_v+1).toString())
//         if( parseInt( _v ) >= 10 ) return (_v+1).toString()
//     }
//     return `${_k(_date.month)}.${_date.year}`
// }   

// // in -> Object { month: 10, year: 2023 } == out - 2023-11-%
// export function format_report_period_date_3( _date ){
//     let _k = _v => {
//         if( parseInt( _v ) < 10 ) return '0'+((_v+1).toString())
//         if( parseInt( _v ) >= 10 ) return (_v+1).toString()
//     }
//     return `${_date.year}-${_k(_date.month)}-%`
// }   


// export function parseNumber( v ){
//     var e = parseFloat( v )
//     var e = e.toFixed(2)
//     return parseFloat( e )
// }

